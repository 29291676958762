import React from 'react';
import { graphql } from 'gatsby';

import DefaultTemplate from './defaultTemplate';
import { createUseStyles } from 'react-jss';
import { SocialMedia } from '../components/utility/socialMedia';

const useStyles = createUseStyles({
  content: {
    paddingBottom: 'var(--size-4x)'
  }
});

export default function Article(props) {
  const classes = useStyles();
  return (
    <DefaultTemplate
      markdown={props.data.markdownRemark}
      header={props.pageContext.header}
      contentClass={classes.content}
      footer={<SocialMedia path={props.location.pathname} />}
      {...props}
    />
  );
}

export const pageQuery = graphql`
  query($slug: String!) {
    markdownRemark(fields: { slug: { eq: $slug } }) {
      ...ArticleQuery
    }
  }
`;
